<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Detalle de {{ datos.nombre }}
        <v-spacer></v-spacer>
        <!-- <div class="mr-3">
          <SearchDataTable
            v-model="search"
          />
        </div> -->
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :sort-by="sortby"
          :headers="headers"
          :items="items"
          :loading="load"
          :search="search"
          :items-per-page="-1"
          :hide-default-header="headers.length == 1"
          hide-default-footer
          dense
          class="px-2 cebra"
        >
          <template v-slot:top>
            <v-row class="d-flex pa-2" no-gutters>
              <v-col cols="12">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          :disabled="load"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable'

export default {
  data () {
    return {
      load: false,
      sortby: '',
      search: '',
      items: [],
      headers: []
    }
  },
  props: {
    value: Boolean,
    datos: {
      type: Object,
      default: {
        nombre: '',
        opcion: null,
        tipo: '',
        id: null
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        this.items = []
        this.headers = []
        this.sortby = ''
        this.search = ''
        let api = ''
        let data = ''
        if (this.datos.tipo == 'PROMO') {
          api = 'get_detalle_promociones'
          data = 'promoDetalles'
          switch (this.datos.opcion) {
            case 1:
              this.sortby = 'dia_nombre'
              this.headers = [{ text: 'Día', value: 'dia_nombre', align:'center', sortable: false }]
              break
            case 2:
              this.sortby = 'categoria_nombre'
              this.headers = [{ text: 'Categoría', value: 'categoria_nombre', align:'center', sortable: false }]
              break
            case 3:
              this.sortby = 'articulo_nombre'
              this.headers = [
                { text: 'Código', value: 'articulo_codigo', align:'end', sortable: false },
                { text: 'Artículo', value: 'articulo_nombre', sortable: false }
              ]
              break
          }
        }
        else if (this.datos.tipo == 'FIN') {
          api = 'get_detalle_financiaciones'
          data = 'detallesEspeciales'
          switch (this.datos.opcion) {
            case 1:
              this.sortby = 'rubro_nombre'
              this.headers = [{ text: 'Rubro', value: 'rubro_nombre', align:'center', sortable: false }]
              break
            case 2:
              this.sortby = 'categoria_nombre'
              this.headers = [{ text: 'Categoría', value: 'categoria_nombre', align:'center', sortable: false }]
              break
            case 3:
              this.sortby = 'marca_nombre'
              this.headers = [{ text: 'Marca', value: 'marca_nombre', align:'center', sortable: false }]
              break
            case 4:
              this.sortby = 'articulo_nombre'
              this.headers = [
                { text: 'Código', value: 'articulo_codigo', align:'end', sortable: false },
                { text: 'Artículo', value: 'articulo_nombre', sortable: false }
              ]
              break
          }
        }
        if (api) {
          await this.$store.dispatch('financiaciones/' + api, {
            opcion: this.datos.opcion,
            id: this.datos.id
          })
            .then((res) => {
              this.items = res[data]
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        this.load = false
      }
    }
  }
}
</script>